import { Button } from 'primereact/button';
import { RefObject, useState } from 'react'
import { useCreatePlanningMutation, useGetAllRouteQuery, useGetCompanyBusesQuery, useGetOccurencesQuery} from '../../service/travelMgrApi';
import { Occurence, Price, Route } from '../../service/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { faChild, faPersonWalkingLuggage } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { LightRouteDisplayTemplate, RouteDisplayTemplate } from '../route/RouteDisplayTemplate';
import { OccurenceDisplayTemplate } from './occurence/OccurenceDisplayTemplate';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from "primereact/inputmask";
import { useFormik } from 'formik';
import { formatDate, formatDateToTime } from '../../DateUtils';
import { Toast } from 'primereact/toast';
import { Bus } from '../../model/bus';
import { Divider } from 'primereact/divider';
import { BusTag, BusTemplate } from '../../component/shared/template/BusTemplate';


interface ScheduleFormValues {
    route:          Route | undefined
    occurence:      Occurence | undefined
    startDate:      Date | undefined
    endDate:        Date | undefined
    time:           Date | undefined
    duration:       string | undefined
    childPrice:     number | undefined
    adultePrice:    number | undefined
    bus:            Bus | undefined
}


export const ScheduleForm = ({visible, setVisible, toastRef } : {visible: boolean, setVisible: Function, toastRef?: RefObject<Toast>}) => {
    const { data: routesData, error: routeQError, isLoading: routeQLoading, status: routeQStatus, isError: routeQIsError, isFetching: routeQIsFetching } = useGetAllRouteQuery()
    const { data: occurencesData, error: occurencesQError, isLoading: occurencesQLoading, status: occurencesQStatus, isError: occurencesQIsError, isFetching: occurencesQIsFetching } = useGetOccurencesQuery()
    const { data: busesQData, error: busesQError, isLoading: busesQLoading, status: busesQStatus, isError: busesQIsError, isFetching: busesQIsFetching } = useGetCompanyBusesQuery()
    const [createPlanningApi] = useCreatePlanningMutation();
    
    const [isCreatingPlanning, setCreatingPlanning] = useState(false)


    const formik = useFormik(
        {
            initialValues: {
                route:      undefined,
                occurence:  undefined,
                startDate:  new Date(),
                endDate:    undefined,
                time:       undefined,
                duration:   undefined,
                childPrice: undefined,
                adultePrice: undefined
            } as ScheduleFormValues,
            validationSchema: Yup.object({
                route:      Yup
                            .object()
                            .required("L'itinéraire est obligatoire"),
                occurence:   Yup
                            .object()
                            .required("La fréquence est obligatoire"),
                startDate:  Yup
                            .date()
                            .required("La date de debut du planning est obligatoire"),
                endDate:    Yup
                            .date()
                            .required("La date de fin du planning est obligatoire"),
                time:       Yup
                            .date()
                            .required("L'heure de debut du voyage est obligatoire"),
                duration:   Yup
                            .string()
                            .required("La durée du voyage est obligatoire"),
                childPrice: Yup
                            .number()
                            .required("Le tarif enfant est obligatoire"),
                adultePrice:Yup
                            .number()
                            .required("Le tarif adulte est obligatoire"),
                bus:        Yup
                            .object()
                            .required("Le bus est obligatoire")
            }),
            onSubmit: values => onPlanningCreation(values as ScheduleFormValues)
        }
    )

    const onPlanningCreation = async (formValues: ScheduleFormValues) => {
        if(!formik.isValid) return
        setCreatingPlanning(true)
		try {
            const prices : Price[] = [{fareClass: {code: "CHILD", label: "Enfant"}, amount: formValues.childPrice as number}, {fareClass: {code: "ADULT", label: "Adulte"}, amount: formValues.adultePrice as number}]
            await createPlanningApi({
                routeId:        formValues.route?.id as number,
                occurrenceCode: formValues.occurence?.code as string,
                startDate:      formatDate(formValues.startDate as Date),
                endDate:        formatDate(formValues.endDate as Date),
                time:           formatDateToTime(formValues.time as Date),
                duration:       formValues.duration as string,
                prices:         prices,
                busId:            formValues.bus?.id as number
            })
            setVisible(false)
            toastRef?.current?.show(
                {
                    severity: 'success', 
                    summary: 'Success', 
                    detail: 'Planning crée avec succès', 
                    life: 4000
                }
            )
		} catch (error) {
            toastRef?.current?.show(
                {
                    severity: 'error', 
                    summary: 'Erreur', 
                    detail: 'Erreur lors de la création du bus', 
                    life: 4000
                }
            )
			throw error
		} finally{
            setCreatingPlanning(false)
        }
	}

    const footerContent = (
        <div className='pr-4'>
            <Divider />
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" size='small' icon="pi pi-check" disabled={!formik.isValid} loading={isCreatingPlanning} autoFocus onClick={(e) => formik.handleSubmit()}/>
        </div>
    )

    return (
        <Dialog
            header="Créer un planning de voyage" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false); }}
            footer={footerContent}
            className='w-12 sm:w-10 md:w-8 xl:w-6 zoomin animation-duration-500'
            pt={{
                content: {
                    className: "pb-0"
                }
            }}>
            <form onSubmit={formik.handleSubmit} className="m-0 card border-0 p-0">
                <div className='card border-0 p-1 mb-0'>
                    {isCreatingPlanning && 
                        <div className="text-center" style={{minHeight: '50px'}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                    }
                    <div className="formgrid grid mx-4 mt-4 mb-0">
                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Itinéraire *</label>
                            </div>
                            <Dropdown 
                                loading={routeQLoading || routeQIsFetching} 
                                inputId='route'
                                name='route'
                                value={formik.values.route} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.route && formik.errors.route !== undefined}
                                itemTemplate={(route: Route) => <RouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={1} />} 
                                valueTemplate={(route: Route) => route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> : <div>Choisissez un itinéraire</div>}  
                                scrollHeight="400px"
                                options={routesData} 
                                optionLabel="name" 
                                placeholder="Choisissez un itinéraire"
                                aria-describedby="route-help"
                                className="w-full p-inputtext-sm" />
                            {formik.touched.route && formik.errors.route && <small className='text-red-500' id="route-help">{formik.errors.route+""}</small>}
                        </div>

                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Fréquence *</label>
                            </div>
                            <Dropdown
                                inputId="occurence"
                                name='occurence'
                                loading={occurencesQLoading || occurencesQIsFetching} 
                                value={formik.values.occurence} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.occurence && formik.errors.occurence !== undefined}
                                itemTemplate={(occurence: Occurence) => <OccurenceDisplayTemplate showDescription={true} occurence={occurence}/>} 
                                valueTemplate={(occurence: Occurence) => occurence ? <OccurenceDisplayTemplate showDescription={true} occurence={occurence}/> : <div>Choisissez une fréquence</div>} 
                                options={occurencesData} 
                                optionLabel="label" 
                                placeholder="Choisissez une fréquence"
                                aria-describedby="occurence-help"
                                className="w-full p-inputtext-sm" />
                            {formik.touched.occurence && formik.errors.occurence && <small className='text-red-500' id="occurence-help">{formik.errors.occurence+""}</small>}
                        </div>

                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Date Début *</label>
                            </div>
                            <Calendar
                                inputId="startDate"
                                name='startDate'
                                value={formik.values.startDate}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.startDate && formik.errors.startDate !== undefined}
                                dateFormat="dd MM yy"
                                minDate={new Date()}
                                locale='fr' 
                                selectionMode="single"
                                aria-describedby="startDate-help"
                                className='w-full p-inputtext-sm'
                                />
                            {formik.touched.startDate && formik.errors.startDate && <small className='text-red-500' id="startDate-help">{formik.errors.startDate+""}</small>}
                        </div>

                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Date fin *</label>
                            </div>
                            <Calendar
                                inputId="endDate"
                                name='endDate'
                                value={formik.values.endDate} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.endDate && formik.errors.endDate !== undefined}
                                dateFormat="dd MM yy"
                                minDate={new Date()}
                                locale='fr' 
                                selectionMode="single"
                                aria-describedby="endDate-help"
                                className='w-full p-inputtext-sm'
                                />
                            {formik.touched.endDate && formik.errors.endDate && <small className='text-red-500' id="endDate-help">{formik.errors.endDate+""}</small>}
                        </div>

                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Horaire *</label>
                            </div>
                            <div>
                                <Calendar
                                    inputId='time'
                                    name='time'
                                    value={formik.values.time} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.time && formik.errors.time !== undefined}
                                    timeOnly
                                    className="w-full p-inputtext-sm"
                                    aria-describedby="time-help"
                                    pt={{container: {className: 'w-full'}}} />
                                {formik.touched.time && formik.errors.time && <small className='text-red-500' id="time-help">{formik.errors.time+""}</small>}
                            </div>
                        </div>

                        <div className="field col-12 lg:col-6 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="duration">Durée du voyage *</label>
                            </div>
                            <InputMask
                                id="duration"
                                name='duration'
                                mask="99 h 99 min"
                                placeholder="06 h 30 min"
                                value={formik.values.duration} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.duration && formik.errors.duration !== undefined}
                                aria-describedby="duration-help"
                                className='w-full p-inputtext-sm'
                                />
                            {formik.touched.duration && formik.errors.duration && <small className='text-red-500' id="duration-help">{formik.errors.duration+""}</small>}
                        </div>

                        <div className="field col-12 lg:col-4 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Prix enfant *</label>
                            </div>
                            <div className="p-inputgroup flex-1" style={{height: 44}}>
                                <span className="p-inputgroup-addon bg-primary">
                                    <FontAwesomeIcon icon={faChild} size='xl' />
                                </span>
                                <InputNumber 
                                    inputId="childPrice"
                                    name='childPrice'
                                    value={formik.values.childPrice} 
                                    onValueChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.childPrice && formik.errors.childPrice !== undefined}
                                    mode="currency" 
                                    currency="XOF"
                                    locale="fr"
                                    aria-describedby="childPrice-help"
                                    className='w-full p-inputtext-sm'
                                    />
                            </div>
                            {formik.touched.childPrice && formik.errors.childPrice && <small className='text-red-500' id="childPrice-help">{formik.errors.childPrice+""}</small>}

                        </div>

                        <div className="field col-12 lg:col-4 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Prix adulte *</label>
                            </div>
                            <div className="p-inputgroup flex-1" style={{height: 44}}>
                                <span className="p-inputgroup-addon bg-primary">
                                    <FontAwesomeIcon icon={faPersonWalkingLuggage} className='' size='xl' />
                                </span>
                                <InputNumber 
                                    inputId="adultePrice"
                                    name='adultePrice'
                                    value={formik.values.adultePrice}
                                    onValueChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.adultePrice && formik.errors.adultePrice !== undefined}
                                    mode="currency" 
                                    currency="XOF" 
                                    locale="fr"
                                    aria-describedby="adultePrice-help"
                                    className='w-full p-inputtext-sm'
                                    />
                            </div>
                            {formik.touched.adultePrice && formik.errors.adultePrice && <small className='text-red-500' id="adultePrice-help">{formik.errors.adultePrice+""}</small>}
                        </div>
                        <div className="field col-12 lg:col-4 mb-5">
                            <div className='mb-1'>
                                <label htmlFor="route">Bus *</label>
                            </div>
                            <Dropdown 
                                loading={busesQLoading || busesQIsFetching} 
                                inputId='bus'
                                name='bus'
                                value={formik.values.bus} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.bus && formik.errors.bus !== undefined}
                                itemTemplate={(bus: Bus) => <BusTemplate bus={bus} />}
                                valueTemplate={(bus: Bus) => bus ? <BusTag bus={bus} /> : <div>Choisissez un bus</div>}  
                                scrollHeight="400px"
                                options={busesQData} 
                                optionLabel="busInfos.busNumber"
                                placeholder="Choisir un bus"
                                aria-describedby="bus-help"
                                className="w-full p-inputtext-sm" />
                            {formik.touched.bus && formik.errors.bus && <small className='text-red-500' id="bus-help">{formik.errors.bus+""}</small>}
                        </div>
                    </div>
                </div>
            </form>
        </Dialog>   
    )
}
