import { Card } from "primereact/card"
import { CardHeader } from "../../component/shared/CardHeader"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressSpinner } from "primereact/progressspinner"
import { useDeleteBusMutation, useGetCompanyBusesQuery } from "../../service/travelMgrApi"
import { useRef, useState } from "react"
import { BusForm } from "./form/BusForm"
import { OverlayPanel } from "primereact/overlaypanel"
import { Bus } from "../../model/bus"
import { Toast } from "primereact/toast"
import { BusStatusTableColumnTemplate } from "./table/BusStatusColumnTemplate"
import { BusMaintenanceColumnTemplate } from "./table/BusMaintenanceColumnTemplate"
import { BusActionColumnTemplate } from "./table/BusActionColumnTemplate"
import { BusInspectionColumnTemplate } from "./table/BusInspectionColumnTemplate"
import { BusStatusChangeForm } from "./table/BusStatusChangeForm"
import { EmptyTableMessage } from "../../component/shared/table/EmptyTableMessage"


export const FleetHomePage = () => {

    const statusChangeFormRef = useRef<OverlayPanel>(null);
    const toast = useRef<Toast>(null);

    //API
    const { data: busesQData, error: busesQError, isLoading: busesQLoading, status: busesQStatus, isError: busesQIsError, isFetching: busesQIsFetching } = useGetCompanyBusesQuery()
    const [deleteBusApi] = useDeleteBusMutation()

    const [visible, setVisible] = useState<boolean>(false)
    const [tableSselectedBus, setTableSelectedBus] = useState<any>()
    const [selectedBus, setSelectedBus] = useState<Bus>()

    const onBusDelete = (busId: number) =>{
        try {
            deleteBusApi(busId)
            toast.current?.show({
                severity: 'success',
                summary: 'Succès',
                detail: 'Bus supprimé avec succès'
            })
        } catch (error) {
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Erreur lors de la suppression du bus',
                life: 4000
            })
        }
    }


    return <Card title={<CardHeader title="Gestion de la flotte" buttonLabel="Bus" onButtonClick={() => setVisible(true)} />} className='shadow-none' >
        <div>
            <OverlayPanel ref={statusChangeFormRef}>
                {<BusStatusChangeForm selectedBus={selectedBus}  statusChangeFormRef={statusChangeFormRef} />}
            </OverlayPanel>

            {(busesQLoading || busesQIsFetching) &&
                <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            }

            {busesQData && 
                <DataTable 
                    value={busesQData} size='small' rows={6} paginator={true}
                    alwaysShowPaginator={false} dataKey="id" emptyMessage={<EmptyTableMessage message="Aucun bus enregistré" callToActionLabel="Enregistrer un bus" callToAction={setVisible} />}
                    selectionMode="single" selection={tableSselectedBus} tableStyle={{ minWidth: '50rem'}}
                    onSelectionChange={(e) => setTableSelectedBus(e.value as Bus)}
                    pt={{
                        wrapper: {
                            className: "common-scroll"
                        }
                    }}>
                    <Column field="busInfos.busNumber" header="Nº bus" sortable />
                    <Column field="status.code" header="Status" 
                        body={(bus: Bus) => <BusStatusTableColumnTemplate bus={bus} setSelectedBus={setSelectedBus} statusChangeFormRef={statusChangeFormRef} />} sortable />
                    <Column field="capacity" header="Capacité"  sortable />
                    <Column field="busInfos.registrationNumber" header="Nº immatriculation" sortable />
                    <Column field="busInfos.model" header="Modèle" sortable />
                    <Column field="busInfos.lastInspectionDate" header="Visite technique" 
                        body={(bus: Bus) => <BusInspectionColumnTemplate bus={bus} />} sortable/>
                    <Column field="busInfos.lastMaintenanceDate" header="Maintenance" 
                        body={(bus: Bus) => <BusMaintenanceColumnTemplate bus={bus} />} sortable/>
                    <Column header="Actions" style={{ flex: '0 0 4rem' }} 
                        body={(bus: Bus) => <BusActionColumnTemplate bus={bus} onBusDelete={onBusDelete}/>} bodyStyle={{ width: '3rem' }}></Column>
                </DataTable>
            }

            <BusForm visible={visible} setVisible={setVisible} toastRef={toast} />
            <Toast ref={toast} position='bottom-right' />
        </div>
    </Card>

}