import { Bus } from "../../../model/bus"
import { formatDateString } from "../../../DateUtils"

export const BusMaintenanceColumnTemplate = ({bus}: {bus: Bus}) => (
    <div className="flex justify-content-center align-content-center">
        <div>
            <small className="white-space-nowrap overflow-hidden text-overflow-ellipsis block">Précédente : {formatDateString(bus.busInfos.lastMaintenanceDate)}</small>
            <small className="white-space-nowrap overflow-hidden text-overflow-ellipsis block">Suivante : {formatDateString(bus.busInfos.lastMaintenanceDate)}</small>
            <small className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{bus.busInfos.maintenanceCriterion.prefix + bus.busInfos.maintenanceFrequency+bus.busInfos.maintenanceCriterion.suffix}</small>
        </div>
    </div>
)