import React from 'react'
import { Commodity } from '../../../model/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlRice, faCircle, faPlug, faToilet, faTv, faWifi } from '@fortawesome/free-solid-svg-icons'


export const CommodityIcon = ({commodity}:{commodity: Commodity}) => {

    let iconDefinition
    switch(commodity.code) {
        case "FREE_WIFI" : 
            iconDefinition = faWifi
            break
        case "ELECTRICAL_OUTLET":
            iconDefinition = faPlug
            break
        case "TELEVISION":
            iconDefinition = faTv
            break
        case "TOILET":
            iconDefinition = faToilet
            break
        case "FREE_FOOD":
            iconDefinition = faBowlRice
            break
        default: iconDefinition = faCircle
    }

    return <FontAwesomeIcon icon={iconDefinition} className="mr-2" color='gray' />
}


