import { OverlayPanel } from "primereact/overlaypanel"
import { RefObject } from "react"
import { Bus } from "../../../model/bus"
import { Badge } from "primereact/badge"
import { useChangeBusStatusMutation, useGetBusStatusesQuery } from "../../../service/travelMgrApi"
import { getBusStatusSeverity } from "../../../utils"


export const BusStatusChangeForm = ({selectedBus, statusChangeFormRef}: {selectedBus?: Bus, statusChangeFormRef: RefObject<OverlayPanel>}) => {
    const { data: busStatusData, error: busStatusQError, isLoading: busStatusQLoading, status: busStatusQStatus, isError: busStatusQIsError, isFetching: busStatusQIsFetching } = useGetBusStatusesQuery()
    const [changeBusStatusApi] = useChangeBusStatusMutation()
    
    return <div className="flex flex-column gap-3">
        {busStatusData?.filter(status => selectedBus?.status.code !== status.code).map(status => 
        <Badge
            onClick={(e) => {
                if(selectedBus) {
                    changeBusStatusApi({busId: selectedBus.id, statusCode: status.code})
                    if(statusChangeFormRef && statusChangeFormRef.current) {
                        statusChangeFormRef.current.hide()
                    }
                }
            }}
            className="cursor-pointer hover:border-gray-300 border-1" 
            value={status.label} severity={getBusStatusSeverity(status)} />
        )
    }
</div>

}