import { TSeatMap } from "../../../module/bus-layout/components/SeatMap/SeatMap.type"

export const createIntialBusLayout =(maxColumn: number) : TSeatMap => {
    return {
        totalArea: 1,
        maxSeatPerRow: maxColumn,
        areas: [
            {
                areaId: 1,
                grids: [
                    {
                        gridRowId: 0,
                        seats: [
                            {
                                gridSeatNum: 1,
                                seatId: 0,
                                isDriverSeat: true,
                                isReserved: true,
                                seatDisplayName: 'Chauffeur'
                            }
                        ]
                    }
                ]

            }
        ]
    }
}