export type TSeatMap = {
  totalArea: number;
  maxSeatPerRow: number;
  areas: TSeatMapArea[];
};

export type TSeatMapArea = {
  areaId: number | string;
  areaName?: string;
  grids: TSeatMapGrid[];
};

export type TSeatMapGrid = {
  gridRowId: number;
  seats: TSeat[];
}

export type SeatType = "TOILET" | "DOOR" | "SPACE"

export type TSeat = {
  gridSeatNum: number;
  seatId: number | string;
  seatDisplayName?: string;
  isDriverSeat?: boolean;
  type?: SeatType;
  isReserved?: boolean;
  price?: number;
};

export enum EnumSeatMapLegendPosition {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

export type TSeatMapProps = {
  layout: TSeatMap;
  handleSeatClick?: Function;
  onContextMenu?: Function;
  selectedSeatIds?: Array<number | string>;
  seatIcon?: JSX.Element;
  selectedSeatIcon?: JSX.Element;
  reservedSeatIcon?: JSX.Element;
  driverSeatIcon?: JSX.Element;
  displayLegend?: boolean;
  seatMapLegendPostion?: EnumSeatMapLegendPosition;
};
