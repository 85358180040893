import { Button } from "primereact/button";


export const EmptyTableMessage = ({message, callToAction, callToActionLabel}: {message: string, callToAction: Function, callToActionLabel: string}) => (
    <div className='flex flex-column align-items-center'>
        <div className='my-2'>
            {message}
        </div>
        <div className='my-2 flex gap-3'>
        <Button label={callToActionLabel} size='small' icon="pi pi-map-marker" onClick={() => callToAction(true)}/>
        </div>
    </div>
)