import { SeatNamingConfig } from "../../../type/bus.type"
import { ClassicSeatNamingConfigIcon } from "./ClassicSeatNamingConfigIcon"
import { SymetricSeatNamingConfigIcon } from "./SymetricSeatNamingConfigIcon"



export const SeatNamingConfigIcon = ({seatNamingConfig}:{seatNamingConfig: SeatNamingConfig}) => {

    switch(seatNamingConfig.code) {
        case "CLASSIC" : 
            return <ClassicSeatNamingConfigIcon />
        case "SYMETRIC":
            return <SymetricSeatNamingConfigIcon />
    }
}
