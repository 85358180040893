import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import * as Yup from 'yup';
import { Commodity } from '../../../model/common'
import { BusInfosRequest, MaintenanceTrackingCriterion } from '../../../model/bus'
import { InputNumber } from 'primereact/inputnumber'
import { Divider } from 'primereact/divider'
import { formatDate, parseDateString } from '../../../DateUtils'
import { useGetCommodityQuery, useGetInspectionFrequencyQuery, useGetMaintenanceCriteriaQuery } from '../../../service/travelMgrApi'
import { CommodityIcon } from '../../../component/shared/icons/CommodityIcon'

import { MaintenanceCriterionIcon } from '../../../component/shared/icons/MaintenanceCriterionIcon'


export interface BusInformationStepProps {
    busInfos: BusInfosRequest,
    onValidate: Function,
    onCancel: Function
}

export const BusInformationStep = ({busInfos, onValidate, onCancel} : BusInformationStepProps) => {

    const { data: commodityQData, error: commodityQError, isLoading: commodityQLoading, isError: commodityQIsError, isFetching: commodityQIsFetching } = useGetCommodityQuery()
    const { data: maintenanceCriteriaQData, error: maintenanceCriteriaQError, isLoading: maintenanceCriteriaQLoading, isError: maintenanceCriteriaQIsError, isFetching: maintenanceCriteriaQIsFetching } = useGetMaintenanceCriteriaQuery()
    const { data: inspectionFrequencyQData, error: inspectionFrequencyQError, isLoading: inspectionFrequencyQLoading, isError: inspectionFrequencyQIsError, isFetching: inspectionFrequencyQIsFetching } = useGetInspectionFrequencyQuery()

    const formik = useFormik(
        {
            initialValues : {
                busNumber: busInfos.busNumber,
                model: busInfos.model,
                registrationNumber: busInfos.registrationNumber,
                commodities: busInfos.commodities,
                lastInspectionDate: busInfos.lastInspectionDate ? parseDateString(busInfos.lastInspectionDate): null,
                inspectionFrequency: busInfos.inspectionFrequency,
                maintenanceCriterion: busInfos.maintenanceCriterion,
                maintenanceFrequency: busInfos.maintenanceFrequency,
                lastMaintenaceDate: busInfos.lastMaintenanceDate? parseDateString(busInfos.lastMaintenanceDate) : null
            },
            validationSchema: Yup.object({
                busNumber :     Yup
                                .string()
                                .required("Le numéro de bus est obligatoire")
                                .min(2, "Le numéro de bus doit avoir au moins 2 caractères.")
                                .max(5, "Le numéro de bus est limité à 5 caractères maximum."),
                model:          Yup
                                .string()
                                .required("Le modèle de bus est obligatoire")
                                .min(2, "Le modèle de bus doit avoir au moins 2 caractères.")
                                .max(32, "Le modèle de bus est limité à 32 caractères maximum."),
                registrationNumber : Yup
                                .string()
                                .required("Le numéro d'enregistrment est obligatoire")
                                .min(5, "Le modèle de bus doit avoir au moins 5 caractères.")
                                .max(20, "Le modèle de bus est limité à 20 caractères maximum."),
                lastInspectionDate : Yup
                                .date()
                                .required("La date de la dernière visite technique est obligatoire"),
                inspectionFrequency:Yup
                                .object()
                                .required("La fréquence de la visite technique est obligatoire"),
                maintenanceCriterion : Yup
                                .object()
                                .required("Le critère de suivi de maintenance est obligatoire"),
                maintenanceFrequency: Yup
                                .number()
                                .required("La fréquence de maintenance est obligatoire"),
                lastMaintenaceDate: Yup
                                .date()
                                .required("La date de la dernière maintenance est obligatoire")
            }),
            onSubmit: values => onValidate({
                busNumber:                    values.busNumber,
                registrationNumber:           values.registrationNumber,
                model:                        values.model,
                lastInspectionDate:           formatDate(values.lastInspectionDate as Date),
                inspectionFrequency:          values.inspectionFrequency,
                commodities:                  values.commodities,
                maintenanceCriterion:         values.maintenanceCriterion,
                maintenanceFrequency:         values.maintenanceFrequency,
                lastMaintenanceDate:           formatDate(values.lastMaintenaceDate as Date)
            } as BusInfosRequest)
        }
    )

    

    const commodityTemplate = (commodity: Commodity) => {
        return (
            <div className="flex align-items-center">
                <CommodityIcon commodity={commodity} />
                <div>{commodity.label}</div>
            </div>
        );
    }

    const maitenanceCriterionTemplate = (criterion: MaintenanceTrackingCriterion) => {
        return (
            <div className="flex align-items-center">
                <MaintenanceCriterionIcon  criterion={criterion}/>
                <div>{criterion.label}</div>
            </div>
        );
    }


    return <form onSubmit={formik.handleSubmit} className="m-0">
        <div className='card border-0 p-0'>
            <div className='my-4 flex flex-column gap-8'>
                <div className="formgrid grid">
                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <InputText 
                                id="busNumber"
                                name="busNumber"
                                invalid={formik.touched.busNumber && formik.errors.busNumber !== undefined}
                                value={formik.values.busNumber} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                aria-describedby="busNumber-help"
                                className="w-full p-inputtext-sm"/>
                            <label htmlFor="busNumber">Numéro du bus *</label>
                        </FloatLabel>
                        {formik.touched.busNumber && formik.errors.busNumber && <small className='text-red-500' id="busNumber-help">{formik.errors.busNumber}</small>}
                    </div>

                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <InputText 
                                id="model"
                                name="model"
                                invalid={formik.touched.model && formik.errors.model !== undefined}
                                value={formik.values.model} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                aria-describedby="model-help"
                                className="w-full p-inputtext-sm"/>
                            <label htmlFor="model">Modèle *</label>
                        </FloatLabel>
                        {formik.touched.model && formik.errors.model && <small className='text-red-500' id="model-help">{formik.errors.model}</small>}
                    </div>
                    
                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <InputText 
                                id="registrationNumber"
                                name="registrationNumber"
                                invalid={formik.touched.registrationNumber && formik.errors.registrationNumber !== undefined}
                                value={formik.values.registrationNumber} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                aria-describedby="registrationNumber-help"
                                className="w-full p-inputtext-sm"/>
                            <label htmlFor="registrationNumber">Immatriculation *</label>
                        </FloatLabel>
                        {formik.touched.registrationNumber && formik.errors.registrationNumber && <small className='text-red-500' id="registrationNumber-help">{formik.errors.registrationNumber}</small>}
                    </div>
                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <MultiSelect
                                id="commodities"
                                name='commodities'
                                loading={commodityQIsFetching || commodityQLoading}
                                value={formik.values.commodities} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                options={commodityQData} 
                                display="chip" 
                                optionLabel="label"
                                maxSelectedLabels={8}
                                className="w-full p-inputtext-sm"
                                itemTemplate={commodityTemplate}
                                showSelectAll={false}
                                style={{height: 44}}
                                pt={{
                                    token : {
                                        style: {height: 32}
                                    },
                                    root: {
                                      className: "pl-0"
                                    }
                                  }} />
                            <label htmlFor="commodity">Commodités</label>
                        </FloatLabel>
                    </div>
                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <Dropdown 
                                id="inspectionFrequency"
                                name='inspectionFrequency'
                                loading={inspectionFrequencyQLoading || inspectionFrequencyQIsFetching}
                                invalid={formik.touched.inspectionFrequency && formik.errors.inspectionFrequency !== undefined}
                                value={formik.values.inspectionFrequency} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                options={inspectionFrequencyQData} 
                                optionLabel="label"
                                aria-describedby="inspectionFrequency-help"
                                className="w-full p-inputtext-sm" />
                            <label htmlFor="inspectionFrequency">Fréquence de la visite technique *</label>
                            </FloatLabel>
                        {formik.touched.inspectionFrequency && formik.errors.inspectionFrequency && <small className='text-red-500' id="inspectionFrequency-help">{formik.errors.inspectionFrequency+""}</small>}
                    </div>
                    <div className="field col-12 lg:col-6 mb-5">
                        <FloatLabel>
                            <Calendar
                                id='lastInspectionDate'
                                name='lastInspectionDate'
                                invalid={formik.touched.lastInspectionDate && formik.errors.lastInspectionDate !== undefined}
                                value={formik.values.lastInspectionDate}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                dateFormat="dd MM yy"
                                locale='fr' 
                                selectionMode="single"
                                maxDate={new Date()}
                                aria-describedby="lastInspectionDate-help"
                                className='w-full p-inputtext-sm'
                                />
                            <label htmlFor="lastInspectionDate">Date de la dernière visite technique *</label>
                            </FloatLabel>
                        {formik.touched.lastInspectionDate && formik.errors.lastInspectionDate && <small className='text-red-500' id="lastInspectionDate-help">{formik.errors.lastInspectionDate+""}</small>}
                    </div>
                    <div className="field col-12 lg:col-4 mb-5">
                        <FloatLabel>
                        <Dropdown 
                                id="maintenanceCriterion"
                                name='maintenanceCriterion'
                                loading={maintenanceCriteriaQIsFetching || maintenanceCriteriaQLoading}
                                invalid={formik.touched.maintenanceCriterion && formik.errors.maintenanceCriterion !== undefined}
                                value={formik.values.maintenanceCriterion} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                options={maintenanceCriteriaQData} 
                                optionLabel="label"
                                itemTemplate={maitenanceCriterionTemplate}
                                aria-describedby="maintenanceCriterion-help"
                                className="w-full p-inputtext-sm" />
                                <label htmlFor="maintenanceCriterion">Critère de maintenance *</label>
                        </FloatLabel>
                        {formik.touched.maintenanceCriterion && formik.errors.maintenanceCriterion && <small className='text-red-500' id="maintenanceCriterion-help">{formik.errors.maintenanceCriterion+""}</small>}
                    </div>
                    <div className="field col-12 lg:col-4 mb-5">
                        <FloatLabel>
                            <InputNumber 
                                id="maintenanceFrequency"
                                name="maintenanceFrequency"
                                disabled={formik.values.maintenanceCriterion === undefined}
                                invalid={formik.touched.maintenanceFrequency && formik.errors.maintenanceFrequency !== undefined}
                                value={formik.values.maintenanceFrequency}
                                prefix={formik.values.maintenanceCriterion ? (formik.values.maintenanceCriterion as MaintenanceTrackingCriterion).prefix : "" }
                                suffix={formik.values.maintenanceCriterion ? (formik.values.maintenanceCriterion as MaintenanceTrackingCriterion).suffix : "" }
                                locale='fr'
                                onValueChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                aria-describedby="maintenanceFrequency-help"
                                className="w-full p-inputtext-sm"/>
                            <label htmlFor="maintenanceFrequency">Fréquence de la maintenance *</label>
                        </FloatLabel>
                        {formik.touched.maintenanceFrequency && formik.errors.maintenanceFrequency && <small className='text-red-500' id="maintenanceFrequency-help">{formik.errors.maintenanceFrequency}</small>}
                    </div>
                    <div className="field col-12 lg:col-4 mb-5">
                        <FloatLabel>
                            <Calendar
                                id='lastMaintenaceDate'
                                name='lastMaintenaceDate'
                                invalid={formik.touched.lastMaintenaceDate && formik.errors.lastMaintenaceDate !== undefined}
                                value={formik.values.lastMaintenaceDate}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                dateFormat="dd MM yy"
                                locale='fr' 
                                selectionMode="single"
                                maxDate={new Date()}
                                aria-describedby="lastMaintenaceDate-help"
                                className='w-full p-inputtext-sm'
                                />
                                <label htmlFor="lastMaintenaceDate">Date de la dernière maintenance *</label>
                        </FloatLabel>
                        {formik.touched.lastMaintenaceDate && formik.errors.lastMaintenaceDate && <small className='text-red-500' id="lastMaintenaceDate-help">{formik.errors.lastMaintenaceDate+""}</small>}
                    </div>
                </div>
            </div>

            <div className="flex flex-column justify-content-end">
                <Divider />
                <div className='flex justify-content-end'>
                    <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => onCancel()} className="p-button-text" />
                    <Button label="Suivant" type='submit' icon="pi pi-arrow-right" iconPos="right" size='small'disabled={!formik.isValid} />
                </div>
            </div>

        </div>
    </form>

}