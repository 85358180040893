import { Badge } from "primereact/badge"
import { getBusStatusSeverity } from "../../../utils"
import { Bus } from "../../../model/bus"
import { RefObject } from "react"
import { OverlayPanel } from "primereact/overlaypanel"

export const BusStatusTableColumnTemplate = ({bus, statusChangeFormRef, setSelectedBus}: {bus: Bus, statusChangeFormRef: RefObject<OverlayPanel>, setSelectedBus: Function}) => (
    <div className="flex justify-content-center align-content-center">
            <Badge value={bus.status.label} severity={getBusStatusSeverity(bus.status)}
                size="normal" className="px-4 justify-content-center white-space-nowrap text-overflow-ellipsis"
                onClick={(e) => {
                    if(statusChangeFormRef && statusChangeFormRef.current) {
                        setSelectedBus(bus)
                        statusChangeFormRef.current.toggle(e)
                    }
                }}/>
    </div>
)