import { useState } from "react";

export const FreeSpaceIcon = ({ ...props }) => {
  
  const [opacity, setOpacity] = useState(0);

  
  return (
    <svg
  width="60"
  height="52"
  viewBox="0 0 60 52"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style={{opacity: opacity, transition: "opacity 0.3s"}}
  onMouseOver={() => setOpacity(1)}
  onMouseOut={() => setOpacity(0)}
>
  <rect
    x="10"
    y="5"
    width="40"
    height="42"
    rx="2.5"
    fill="#FFF"
    stroke="#B8B8B8"
    stroke-width="1.5"
    stroke-dasharray="1 3"
    stroke-linejoin="round"
  ></rect>

  <text 
    x="30" 
    y="26" 
    fill="#000" 
    font-size="8" 
    text-anchor="middle" 
    dominant-baseline="middle"
  >
    {props.label}
  </text>
</svg>
  )

  }
