import { Button } from 'primereact/button'
import { FloatLabel } from 'primereact/floatlabel'
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { SeatType, TSeat, TSeatMap, TSeatMapGrid } from '../../../module/bus-layout/components/SeatMap/SeatMap.type'
import { BUS_LAYOUT_CONFIGS, BusColumConfig, SEAT_NEAMING_CONFIGS, SeatNamingConfig } from '../../../type/bus.type'
import { ContextMenu } from 'primereact/contextmenu'
import { MenuItem } from 'primereact/menuitem'
import { Dropdown } from 'primereact/dropdown'
import { BusConfigTemplate } from '../BusConfigTemplate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCouch, faDoorOpen, faToilet } from '@fortawesome/free-solid-svg-icons'
import { SeatMap } from '../../../module/bus-layout/components/SeatMap/SeatMap'
import { useFormik } from 'formik'
import { createIntialBusLayout } from './utils'
import { MultiSelectChangeEvent } from 'primereact/multiselect'
import { BusLayoutConfigRequest } from '../../../model/bus'
import { SeatNamingConfigIcon } from '../../../component/shared/icons/SeatNamingConfigIcon'

export interface BusLayoutStepProps {
    busLayoutRequest:  BusLayoutConfigRequest
    onBack:         Function
    onCancel:       Function
    onValidate:     Function
}

export const BusLayoutStep = ({busLayoutRequest, onBack, onCancel, onValidate} : BusLayoutStepProps) => {

    const formik = useFormik({
        initialValues: {
            busLayoutFrame:     busLayoutRequest.busLayoutFrame,
            seatNamingConfig:   busLayoutRequest.seatNamingConfig,
            row:                busLayoutRequest.row
        },
        onSubmit: values => onValidate(constructBusLayoutRequest())
    })

    
    const [busLayout, setBusLayout] = useState<TSeatMap>(busLayoutRequest.layout)
    const [specialSeats, setSpecialSeats] = useState<Map<number, SeatType>>(busLayoutRequest.customizedSeats)
    const [selectedSeat, setSelectedSeat] = useState<TSeat>()

    const contextMenu = useRef<ContextMenu>(null);

    const busconfigTemplate = (config: BusColumConfig) => {
        return <BusConfigTemplate config={config} />
    }

    const addSpecialSeats = (seat: TSeat, seatType?: SeatType) => {
        const seatId = seat?.seatId as number
        if(seatType === undefined) {
            const newSpecialSeats = new Map(specialSeats)
            newSpecialSeats.delete(seatId)
            setSpecialSeats(newSpecialSeats)
        } else {
            const newSpecialSeats = new Map(specialSeats)
            newSpecialSeats.set(seatId, seatType)
            setSpecialSeats(newSpecialSeats)
        }
    }

    const onContextMenu = (e:SyntheticEvent, seat: TSeat) => {
        setSelectedSeat(seat)
        contextMenu.current?.show(e)
    }

    const getContextMenuItems = useCallback(() : MenuItem[] => {
        const items: MenuItem[] = [
            { label: 'Siège', visible: selectedSeat?.type !== undefined, command : () => {selectedSeat && addSpecialSeats(selectedSeat, undefined)}, icon: <FontAwesomeIcon icon={faCouch} className='pr-2 text-gray-400'/> },
            { label: 'Porte', visible: selectedSeat?.type !== "DOOR", command : () => {selectedSeat && addSpecialSeats(selectedSeat, "DOOR")}, icon: <FontAwesomeIcon icon={faDoorOpen} className='pr-2 text-gray-400'/> },
            { label: 'Toillette', visible: selectedSeat?.type !== "TOILET", command : () => {selectedSeat && addSpecialSeats(selectedSeat, "TOILET")}, icon: <FontAwesomeIcon icon={faToilet} className='pr-2 text-gray-400'/> },
            { label: 'Vide', visible: selectedSeat?.type !== "SPACE", command : () => {selectedSeat && addSpecialSeats(selectedSeat, "SPACE")}, icon: <FontAwesomeIcon icon={faCircle} className='pr-2 text-gray-400'/> }
        ];
        return items
    }, [selectedSeat])

   
    const createBusLayout = () => {

        const leftColumnNum = formik.values.busLayoutFrame.lefColumnNum
        const rightColumnNum = formik.values.busLayoutFrame.rightColumnNum
        let rowsNum = formik.values.row as number

        let currentSeatId = 1;
        const totalColumn = leftColumnNum + rightColumnNum +1
        const initialLayout = createIntialBusLayout(totalColumn)
        let specialsSeat = 0

        for (let rowIndex = 1; rowIndex < rowsNum+1; rowIndex++) {

            const seats = []
            for (let seatNum = 1; seatNum < totalColumn+1; seatNum++) {
                if(seatNum !== leftColumnNum +1 || rowIndex === rowsNum) {
                    const seatType = specialSeats.get(currentSeatId)
                    if(seatType) {
                        specialsSeat = specialsSeat + 1
                    }
                    const seatName = formik.values.seatNamingConfig.processor(rowIndex, currentSeatId, seatNum, leftColumnNum, rightColumnNum)
                    const seat : TSeat = {
                        gridSeatNum : seatNum,
                        seatId: currentSeatId,
                        type: seatType,
                        seatDisplayName: seatName - specialsSeat +""
                    }
                    currentSeatId = currentSeatId + 1
                    seats.push(seat)
                }
            }

            const seatMapGrid : TSeatMapGrid = {
                gridRowId: rowIndex,
                seats: seats
            }
            initialLayout.areas[0].grids.push(seatMapGrid)

        }
        setBusLayout(initialLayout)
    }

    useEffect(()=> {
        createBusLayout()
    }, [formik.values.busLayoutFrame, formik.values.row, formik.values.seatNamingConfig, specialSeats])

    const constructBusLayoutRequest = () : BusLayoutConfigRequest => {
        return {
            layout:             busLayout,
            busLayoutFrame:     formik.values.busLayoutFrame,
            row:                formik.values.row,
            seatNamingConfig:   formik.values.seatNamingConfig,
            customizedSeats:    specialSeats
        }
    }

    const seatNamingConfigTemplate = (seatNamingConfig: SeatNamingConfig) => {
        return (
            <div className="flex flex-column align-items-center">
                <div>{seatNamingConfig.code}</div>
                <SeatNamingConfigIcon seatNamingConfig={seatNamingConfig} />
            </div>
        );
    }

        
    return <div className='mt-5'>
        <ContextMenu model={getContextMenuItems()} ref={contextMenu} breakpoint="767px" />
        <div className='flex flex-column lg:flex-row'>
            <div className='mx-7 lg:mx-0 flex-column flex-grow-1'>
                <div className='field col'>
                    <FloatLabel>
                        <Dropdown
                            inputId="city" 
                            itemTemplate={busconfigTemplate}
                            value={formik.values.busLayoutFrame} 
                            onChange={(e: MultiSelectChangeEvent) => formik.setFieldValue("busLayoutFrame", e.value)} 
                            options={[BUS_LAYOUT_CONFIGS.TWO_X_TWO, BUS_LAYOUT_CONFIGS.TWO_X_ONE]} 
                            optionLabel="label" 
                            className="min-h-1 w-full p-inputtext-sm"
                            />
                            <label htmlFor="city">Plan de configuration</label>
                    </FloatLabel>
                </div>
                <div className='field col'>
                    <FloatLabel>
                        <InputNumber 
                            id="stationName" 
                            value={formik.values.row} 
                            onValueChange={(e: InputNumberValueChangeEvent) => formik.setFieldValue("row", e.value)}
                            mode="decimal" 
                            showButtons 
                            min={2} 
                            max={20} 
                            className='w-full p-inputtext-sm'/>
                        <label htmlFor="stationName">Nombre de rangées</label>
                    </FloatLabel>
                </div>
                <div className='field col'>
                    <FloatLabel>
                        <Dropdown
                            inputId="city" 
                            value={formik.values.seatNamingConfig} 
                            onChange={(e: MultiSelectChangeEvent) => formik.setFieldValue("seatNamingConfig", e.value)} 
                            options={[SEAT_NEAMING_CONFIGS.CLASSIC, SEAT_NEAMING_CONFIGS.SYMETRIC]} 
                            optionLabel="code"
                            itemTemplate={seatNamingConfigTemplate}
                            className="min-h-1 w-full p-inputtext-sm"
                            />
                            <label htmlFor="city">System de numérotation des sièges</label>
                    </FloatLabel>
                </div>
            </div>
            
            <div 
                style={{maxHeight: '400px'}}
                className='overflow-y-auto common-scroll flex-grow-1'>
                <SeatMap layout={busLayout} onContextMenu={onContextMenu}/>
            </div>
        </div>

        <div className="flex pt-4 justify-content-end gap-2"> 
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => onCancel()} className="p-button-text" />
            <Button label="Précedent" icon="pi pi-arrow-left" iconPos="left" size='small' onClick={() => onBack(constructBusLayoutRequest())} />
            <Button label="Valider" type='submit' size='small' icon="pi pi-check" onClick={(e) => onValidate(constructBusLayoutRequest())} autoFocus />
        </div>
    </div>

}