import { Planning } from "../../../service/type";
import { Chip } from "primereact/chip";


export const DepatureTimeTemplate = ({planning}: {planning: Planning}) => (
    <Chip label={planning.time} className='bg-primary' style={{color: '#000000'}} pt={{
        label : {
            className: 'my-chip-margin text-center mx-5',
        },
        root : {
            className: 'border-noround'
        }
        }} />
)