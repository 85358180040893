import { Tag } from "primereact/tag";
import { Bus } from "../../../model/bus";
import { CommodityIcon } from "../icons/CommodityIcon";
import { Divider } from "primereact/divider";


export const BusTemplate = ({bus}: {bus: Bus}) => {
    const columnSize = Math.round(Array.from(bus.busInfos.commodities).length/2)
    return <div className="flex gap-3">
        <div className="flex flex-column align-items-center">
            <Tag severity="danger" value={"BUS "+bus.busInfos.busNumber}
                pt={{
                root: {
                    className: "border-noround w-full"
                }
                }}/>
                <div>{bus.busInfos.model}</div>
                <div>{bus.capacity} places</div>
        </div>
        <Divider layout="vertical" />
        <div style={{ display: 'grid', gridTemplateColumns: "auto ".repeat(columnSize) }}>
            {
                Array.from(bus.busInfos.commodities)
                .map(commodity => 
                    <div className="mr-2">
                        <CommodityIcon commodity={commodity} /> {commodity.label}
                    </div>
                )
            }
        </div>
    </div>
}

export const BusTag = ({bus}: {bus: Bus}) => {
    return <div>
        <Tag severity="danger" value={"BUS "+bus.busInfos.busNumber}
        pt={{
        root: {
            className: "border-noround"
        }
        }}/> 
        <span className="ml-3">{bus.capacity} places</span>
    </div>
}

