import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsLeftRightToLine, faCircle, faClock } from '@fortawesome/free-solid-svg-icons'
import { MaintenanceTrackingCriterion } from '../../../model/bus'


export const MaintenanceCriterionIcon = ({criterion}:{criterion: MaintenanceTrackingCriterion}) => {

    let iconDefinition
    switch(criterion.code) {
        case "DISTANCE" : 
            iconDefinition = faArrowsLeftRightToLine
            break
        case "TIME":
            iconDefinition = faClock
            break
        default: iconDefinition = faCircle
    }

    return <FontAwesomeIcon icon={iconDefinition} className="mr-2" color='gray' />
}


