import { BusColumConfig } from "../../type/bus.type"

interface BusConfigTemplateProps {
    config: BusColumConfig
}


export const BusConfigTemplate = ({config}: BusConfigTemplateProps) => {

    return (
        <div className="flex flex-column max-w-30rem">
            <div className='font-bold'>{config.label}</div>
            <small className="text-gray-600" style={{whiteSpace: 'initial'}}>{config.description}</small>
        </div>
    )


}