import { useRef, useState } from 'react'
import { DialogProps } from '../../../propsType';
import { Dialog } from 'primereact/dialog';
import { Stepper, StepperRefAttributes } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BusLayoutStep } from './BusLayoutStep';
import { BusInformationStep } from './BusInformationStep';
import { BusCreateRequest, BusInfosRequest, BusLayoutConfigRequest } from '../../../model/bus';
import { createIntialBusLayout } from './utils';
import { BUS_LAYOUT_CONFIGS, SEAT_NEAMING_CONFIGS } from '../../../type/bus.type';
import { useAddBusMutation } from '../../../service/travelMgrApi';

export const BusForm = ({visible, setVisible, toastRef } : DialogProps) => {
    const stepperRef = useRef<StepperRefAttributes>(null);
    
    const [addBus] = useAddBusMutation();
    
    const [isCreatingBus, setCreatingBus] = useState(false)
    const [busInfos, setBusInfos] = useState<BusInfosRequest>()
    const [busLayoutConfig, setBusLayoutConfig] = useState<BusLayoutConfigRequest>()

    const onBusCreation = async (busLayoutConfig: BusLayoutConfigRequest) => {
        setCreatingBus(true)
        try {
            setBusLayoutConfig(busLayoutConfig)
            addBus({
                busInfos: busInfos,
                busLayoutConfig: busLayoutConfig
            }as BusCreateRequest)
            setVisible(false)
            toastRef?.current?.show(
                {
                    severity: 'success', 
                    summary: 'Success', 
                    detail: 'Bus crée avec succès', 
                    life: 4000
                }
            )
        } catch (error) {
            toastRef?.current?.show(
                {
                    severity: 'error', 
                    summary: 'Erreur', 
                    detail: 'Erreur lors de la création du bus', 
                    life: 4000
                }
            )
        } finally {
            setCreatingBus(false)
        }
	}

    const navigateToBusLayouStep = (busInfoss: BusInfosRequest) => {
        if(stepperRef && stepperRef.current) {
            setBusInfos(busInfoss)
            console.log(busInfos)
            stepperRef.current.nextCallback()
        }
    }

    const navigateToBusInfoStep = (busLayoutConfigRequest: BusLayoutConfigRequest) => {
        if(stepperRef && stepperRef.current) {
            setBusLayoutConfig(busLayoutConfigRequest)
            stepperRef.current.prevCallback()
        }
    }

    return (

        <Dialog
            header="Enregistrer un bus" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false)}} 
            className='w-12 md:w-8 xl:w-6 2xl:w-4 zoomin animation-duration-500'
            pt={{
                content: {
                    className: "pb-0",
                    style: {overflow: "hidden"}
                }
            }}
            >
                <div>
                    {isCreatingBus && 
                        <div className="text-center" style={{minHeight: '50px'}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                    }
            
                    <Stepper ref={stepperRef} headerPosition='right' linear >
                        <StepperPanel header="Infos du bus">
                            <BusInformationStep
                                busInfos={busInfos ? busInfos : {} as BusInfosRequest}
                                onCancel={() => setVisible(false)} 
                                onValidate={navigateToBusLayouStep} />
                        </StepperPanel>
                        <StepperPanel header="Plan du bus">
                            <BusLayoutStep
                                busLayoutRequest={
                                    busLayoutConfig ? busLayoutConfig : {
                                        busLayoutFrame:     BUS_LAYOUT_CONFIGS.TWO_X_TWO,
                                        seatNamingConfig:   SEAT_NEAMING_CONFIGS.CLASSIC,
                                        row:                10,
                                        layout :            createIntialBusLayout(BUS_LAYOUT_CONFIGS.TWO_X_TWO.lefColumnNum + BUS_LAYOUT_CONFIGS.TWO_X_TWO.rightColumnNum+1),
                                        customizedSeats:    new Map()
                                        }
                                    }
                                onCancel={() => setVisible(false)} 
                                onBack={(busLayoutConfigRequest: BusLayoutConfigRequest) => navigateToBusInfoStep(busLayoutConfigRequest)} 
                                onValidate={(busLayoutConfig: BusLayoutConfigRequest) => onBusCreation(busLayoutConfig)}/>
                        </StepperPanel>
                    </Stepper>
                </div>
        </Dialog>
    )
}
