
export interface BusColumConfig {
    lefColumnNum: number,
    rightColumnNum: 1 | 2,
    code: BusColumConfigCode,
    label: string,
    description: string
}

type BusColumConfigCode = 'TWO_X_TWO' | 'TWO_X_ONE'


export const BUS_LAYOUT_CONFIGS : Record<BusColumConfigCode, BusColumConfig> = {
    TWO_X_TWO : {lefColumnNum: 2, rightColumnNum: 2, code: 'TWO_X_TWO', label: '2 colonnes X 2 colonnes', description: '2 blocs avec 2 colonnes de sièges chacune, séparées par une allée centrale.'},
    TWO_X_ONE: {lefColumnNum: 2, rightColumnNum: 1, code: 'TWO_X_ONE', label: '2 colonnes X 1 colonne', description: '1 bloc avec 2 colonnes de sièges et un autre avec une colonne, séparées par une allée centrale.'}
}


type SeatNamingConfigCode = 'CLASSIC' | 'SYMETRIC'

export interface SeatNamingConfig {
    processor: Function,
    code : SeatNamingConfigCode,
    label: string,
    description: string
}

const seatNamingClassicProcessor = (currentRow: number, currentSeatId: number, currentSeatIndexInRow: number, lefColumnNum: number, rightColumnNum: number): number => {
    return currentSeatId
}

const seatNamingSymetricProcessor = (currentRow: number, currentSeatId: number, currentSeatIndexInRow: number, lefColumnNum: number, rightColumnNum: number): number => {
    if(currentSeatIndexInRow > lefColumnNum + 1) {
        if(rightColumnNum == 2) {
            if(currentSeatIndexInRow == lefColumnNum+2) {
                return currentSeatId + 1
            } else{
                return currentSeatId - 1
            }
        }
    }
    
    return currentSeatId


    /*
    if(currentSeatIndexInRow == lefColumnNum +2){
        return currentSeatId + 1
    }

    if(currentSeatIndexInRow == lefColumnNum+3) {
        return currentSeatId -1
    }
    return currentSeatId
    */
}

export const SEAT_NEAMING_CONFIGS : Record<SeatNamingConfigCode, SeatNamingConfig> = {
    CLASSIC: {processor: seatNamingClassicProcessor, code: 'CLASSIC', label: '', description: ''},
    SYMETRIC: {processor: seatNamingSymetricProcessor, code: 'SYMETRIC', label: '', description: ''}
}